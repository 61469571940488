<template>
    <div class="activies">
        <div class="search-container">
            <Input
                :placeholder="'搜索动态' | language"
                search
                v-model="keyword"
                @keyup.enter.native="handleChange"
                @on-change="handleChange"
                style="width: auto"
            />
        </div>
        <div class="list_container">
            <template v-if="list.length">
                <div class="list" v-for="(item, index) in list" :key="index">
                    <div class="date">
                        <img
                            src="http://cdn.sjtuair.com/75e566d0-5185-11ea-910d-3183c11ee068.svg"
                            alt
                        />
                        <div class="date_day">{{ item.day }}</div>
                        <div class="date_year">{{ item.year }}</div>
                    </div>
                    <div class="item_wrap" @click="goTo(item)">
                        <div class="title">{{ item.title }}</div>
                        <div class="content">{{ item.excerpt }}</div>
                    </div>
                    <div class="image">
                        <img
                            v-if="item.cover"
                            v-real-img="item.cover.src"
                            alt
                        />
                    </div>
                </div>
            </template>
            <div class="no-date" :class="{ 'show-no-data': isEmpty }" v-else>
                {{ "没有符合筛选条件的数据" | language }}~
            </div>
        </div>
        <Page
            :total="total"
            :current="pageNum"
            :page-size="pageSize"
            @on-change="handleChangePage"
            style="margin-bottom: 40px"
            show-total
        >
            <span class="ivu-page-total" style="font-size: 16px"
                >{{ "共有" | language }} {{ total }} {{ "条" | language }}</span
            >
        </Page>
    </div>
</template>

<script>
import CommonSearchBar from "../../compontents/information/CommonSearchBar.vue";
import dayjs from "dayjs";
import { DayUtil } from "../../assets/util/day";
export default {
    components: {
        CommonSearchBar,
    },
    data() {
        return {
            list: [],
            keyword: "",
            pageSize: 10,
            pageNum: 1,
            total: 0,
            isEmpty: false,
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        handleChange() {
            this.isEmpty = false;
            this.pageNum = 1;
            this.fetchData();
        },
        // 日期去掉0
        dislodgeZero(str) {
            let strArray = str.split("-");
            strArray = strArray.map(function (val) {
                if (val[0] == "0") {
                    return (val = val.slice(1));
                } else {
                    return val;
                }
            });
            return strArray.join("-");
        },
        async fetchData() {
            // GET /api/information/partNews?keyword=搜索词&pageSize=10&pageNum=0
            let res = await this.$http.get(
                `/information/partNews?keyword=${this.keyword}&pageSize=${
                    this.pageSize
                }&pageNum=${this.pageNum - 1}`
            );
            res.result.map((item, index) => {
                item.year = dayjs(item.writeAt).format("YYYY-MM");
                item.year = this.dislodgeZero(item.year);
                item.day = dayjs(item.writeAt).format("DD");
                item.day = this.dislodgeZero(item.day);
                return item;
            });

            this.list = res.result;
            this.total = res.count;
            if (res.result.length == 0) {
                this.isEmpty = true;
            }
        },
        goTo(item) {
            if (item.href) {
                location.href = item.href;
                return;
            }
            this.$router.push(`/base/govnews/detail/${item._id}`);
        },
        //分页
        handleChangePage(page) {
            document.getElementById("app").scrollIntoView();
            this.pageNum = page;
            this.fetchData();
        },
    },
};
</script>

<style lang="less" scoped>
.activies {
    .search-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        .search-title {
            margin-bottom: 10px;
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            text-align: left;
        }
    }
    .line {
        height: 1px;
        background: rgba(0, 0, 0, 0.25);
        margin-bottom: 40px;
    }
    .list_container {
        display: flex;
        flex-direction: column;
        min-height: 1000px;
        .list {
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
            .date {
                position: relative;
                // width: 80px;
                > img {
                    position: absolute;
                    top: 13px;
                    left: 0;
                }
                .date_day {
                    width: 52px;
                    font-size: 20px;
                    font-weight: 500;
                    color: rgba(247, 181, 0, 1);
                    line-height: 28px;
                }
                .date_year {
                    margin-top: 14px;
                    width: 52px;
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(247, 181, 0, 1);
                    line-height: 17px;
                }
            }
            .item_wrap {
                width: 678px;
                .title {
                    cursor: pointer;
                    text-align: left;
                    font-size: 24px;
                    color: rgba(48, 49, 51, 1);
                    margin-bottom: 20px;
                }
                .content {
                    cursor: pointer;
                    text-align: left;
                    height: 104px;
                    font-size: 16px;
                    color: #000000;
                    opacity: 0.6;
                    line-height: 26px;
                }
            }
            .image {
                width: 257px;
                height: 192px;
                > img {
                    width: 257px;
                    height: 192px;
                    object-fit: cover;
                }
            }
        }
        .no-date {
            width: 200px;
            height: 100px;
            line-height: 100px;
            margin: 60px auto;
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            visibility: hidden;
            opacity: 0;
        }
        .flex {
            flex: 1;
        }
    }
}
@media screen and (max-width: 960px) {
    .activies {
        .list_container {
            .list {
                flex-wrap: wrap;
                .item_wrap {
                    width: 80%;
                    .title {
                        margin-bottom: 10px;
                        font-size: 18px;
                        line-height: 1;
                        height: auto;
                    }
                    .content {
                        font-size: 14px;
                        line-height: 1.5;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        height: auto;
                    }
                }
                .image {
                    width: 100%;
                    margin-top: 10px;
                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
</style>
<style>
.show-no-data {
    visibility: visible !important;
    opacity: 1 !important;
}
</style>
